<template>
    <hotel-profile-component/>
</template>

<script>
    import HotelProfileComponent from "@/components/hotels/HotelProfileComponent";
    
    export default {
        name: "HotelProfile",
        title: "Perfil del Hotel | Turismo BC",
        components: { HotelProfileComponent }
    }
</script>

<style scoped>

</style>